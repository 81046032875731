<script setup lang="ts">
import { Link, usePage } from '@inertiajs/vue3'
import { useI18n } from 'vue-i18n'
import ProductsSlider from './ProductsSlider.vue'
import { onMounted } from 'vue'
import { useTracking } from '@/Composables/tracking'
import { Product } from '@interfaces/product.interface'
import { ArrowRightIcon } from '@heroicons/vue/20/solid'

const props = defineProps<{
    products: Array<Product>,
}>()

const { t } = useI18n()
const { trackProductsListView } = useTracking()

onMounted(() => {
    trackProductsListView(props.products, {
        id: 'new-arrivals',
        name: t('pages.home.new-arrivals.title'),
    })
})
</script>

<template>
    <ProductsSlider
        class="py-16 sm:pb-24"
        :products="products"
    >
        <template #title>
            {{ t('pages.home.new-arrivals.title') }}
        </template>

        <template #title-link>
            <Link
                :href="route('catalog', { _query: { sort: 'newest' } })"
                class="hidden font-medium text-p2 text-primary-600 hover:text-primary-800 md:inline-flex md:items-center group"
            >
            {{ t('pages.home.new-arrivals.all-products') }}
            <ArrowRightIcon class="w-4 h-4 ml-1 transition-transform duration-300 transform group-hover:translate-x-0.5" />
            </Link>
        </template>

        <template #footer>
            <div class="mt-8 text-sm md:hidden">
                <Link
                    :href="route('catalog', { _query: { sort: 'newest' } })"
                    class="inline-flex items-center font-medium text-primary-600 hover:text-primary-800 group"
                >
                {{ t('pages.home.new-arrivals.all-products') }}
                <ArrowRightIcon class="w-4 h-4 ml-1 transition-transform duration-300 transform group-hover:translate-x-0.5" />
                </Link>
            </div>
        </template>
    </ProductsSlider>
</template>
